export enum FeatureFlag {
  Analytics = 'analytics',
  AnalyticsPlanningAssistant = 'analytics.planningAssistant',
  Allocations = 'allocations',
  StoreTransfer = 'storeTransfer',
  SpecialEventsNew = 'specialEventsNew',
  SpecialEvents = 'specialEvents',
  SpecialEventsEventDetails = 'specialEvents.eventDetails',
  ReplenishmentInventories = 'replenishment.inventories',
  InventoryAnalyzeDataButton = 'inventory.analyzeDataButton',
  InventoryTransactions = 'inventory.transactions',
}
