import {
  ref,
  computed,
  toValue,
  watch,
  type Ref,
  type ComputedRef,
  type MaybeRefOrGetter,
} from 'vue';
import { Schema, z } from 'zod';
import { intersection, difference, union, isEqual } from 'lodash-es';

type ColumnsVisibility = Record<string, boolean>;

interface UseTableColumnsSettingsStoreOptions {
  defaultColumnsVisibility: MaybeRefOrGetter<ColumnsVisibility>;
  defaultPinnedColumnsOrder: MaybeRefOrGetter<string[]>;
  defaultColumnsOrder: MaybeRefOrGetter<string[]>;
  customColumns?: MaybeRefOrGetter<string[]>;
}
interface PersistedState {
  columnsVisibility: ColumnsVisibility;
  pinnedColumnsOrder: string[];
  columnsOrder: string[];
}

interface UseTableColumnsSettingsStoreReturn {
  columnsVisibility: Ref<ColumnsVisibility>;
  pinnedColumnsOrder: Ref<string[]>;
  columnsOrder: Ref<string[]>;
  allColumns: ComputedRef<string[]>;
  visibleColumns: ComputedRef<string[]>;
  columnsSettingsTouched: ComputedRef<boolean>;
  resetColumnsSettings: () => void;
  applyPersistedColumnsSettings: (persistedState: PersistedState) => void;
}

export function useTableColumnsSettingsStore(
  options: UseTableColumnsSettingsStoreOptions,
): UseTableColumnsSettingsStoreReturn {
  const customColumns = computed(() => toValue(options.customColumns) ?? []);
  const defaultColumnsVisibility = computed(() => {
    const result = toValue(options.defaultColumnsVisibility);

    customColumns.value.forEach((item) => {
      result[item] = true;
    });

    return result;
  });
  const defaultColumnsOrder = computed(() => {
    const result = toValue(options.defaultColumnsOrder);

    result.push(...customColumns.value);

    return result;
  });
  const defaultPinnedColumnsOrder = computed(() => toValue(options.defaultPinnedColumnsOrder));

  const columnsVisibility = ref<ColumnsVisibility>({ ...defaultColumnsVisibility.value });
  const pinnedColumnsOrder = ref<string[]>([...defaultPinnedColumnsOrder.value]);
  const columnsOrder = ref<string[]>([...defaultColumnsOrder.value]);

  const visibleColumns = computed(() => {
    return Object.entries(columnsVisibility.value).reduce<string[]>((acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }

      return acc;
    }, []);
  });
  const allColumns = computed(() => Object.keys(columnsVisibility.value));

  const columnsSettingsTouched = computed(
    () =>
      !isEqual(defaultColumnsVisibility.value, columnsVisibility.value) ||
      !isEqual(defaultColumnsOrder.value, columnsOrder.value) ||
      !isEqual(defaultPinnedColumnsOrder.value, pinnedColumnsOrder.value),
  );

  function resetColumnsSettings() {
    columnsVisibility.value = {
      ...defaultColumnsVisibility.value,
    };
    columnsOrder.value = [...defaultColumnsOrder.value];
    pinnedColumnsOrder.value = [...defaultPinnedColumnsOrder.value];
  }

  function applyPersistedColumnsSettings(persistedState: PersistedState) {
    const allColumns = [...defaultPinnedColumnsOrder.value, ...defaultColumnsOrder.value];

    if (persistedState.columnsVisibility) {
      try {
        columnsVisibility.value = z
          .object(
            Object.keys(defaultColumnsVisibility.value).reduce<Record<string, Schema>>(
              (acc, key) => {
                acc[key] = z.boolean().catch(defaultColumnsVisibility.value[key]);
                return acc;
              },
              {},
            ),
          )
          .parse(persistedState.columnsVisibility);
      } catch (error) {
        // do nothing
      }
    }

    if (persistedState.pinnedColumnsOrder) {
      try {
        z.array(z.string()).parse(persistedState.pinnedColumnsOrder);

        pinnedColumnsOrder.value = intersection(persistedState.pinnedColumnsOrder, allColumns);
      } catch (error) {
        // do nothing
      }
    }

    // Remove all pinned columns from columns list (in case of collision)
    columnsOrder.value = difference(allColumns, pinnedColumnsOrder.value);

    if (persistedState.columnsOrder) {
      try {
        z.array(z.string()).parse(persistedState.columnsOrder);

        columnsOrder.value = union(
          intersection(persistedState.columnsOrder, columnsOrder.value),
          columnsOrder.value,
        );
      } catch (error) {
        // do nothing
      }
    }
  }

  watch([defaultColumnsVisibility, defaultColumnsOrder, defaultPinnedColumnsOrder], () => {
    resetColumnsSettings();
  });

  return {
    columnsVisibility,
    pinnedColumnsOrder,
    columnsOrder,
    visibleColumns,
    allColumns,
    resetColumnsSettings,
    applyPersistedColumnsSettings,
    columnsSettingsTouched,
  };
}
