import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import { z } from 'zod';
import { StoreNames } from '../../../shared/store-names';
import { useTableColumnsSettingsStore } from '../../../composables';
import { useUiStatesApi } from '../../ui-states';

enum Columns {
  SkuChart = 'sku_chart',
  Size = 'size',
  Replenishment = 'replenishment',
  Sold = 'sold',
  SalesRate = 'sale_rate',
  Stock = 'stock',
  OptimalStock = 'optimal_stock',
  WarehouseInventory = 'wh_qty',
  WhNames = 'wh_names',
  NextReplenishment = 'replenishment_time',
  Coverage = 'coverage',
  ExpectedCoverage = 'expected_coverage',
  Constraints = 'constraints',
}

export const useReplenishmentProductDetailsPageStore = defineStore(
  StoreNames.ReplenishmentProductDetailsPage,
  () => {
    const defaultWidth: number = 680;
    const width = ref<number>(defaultWidth);

    const {
      columnsVisibility,
      columnsOrder,
      pinnedColumnsOrder,
      visibleColumns,
      resetColumnsSettings,
      applyPersistedColumnsSettings,
      columnsSettingsTouched,
    } = useTableColumnsSettingsStore({
      defaultColumnsVisibility: {
        [Columns.SkuChart]: true,
        [Columns.Size]: true,
        [Columns.Replenishment]: true,
        [Columns.Sold]: true,
        [Columns.SalesRate]: false,
        [Columns.Stock]: true,
        [Columns.OptimalStock]: true,
        [Columns.WarehouseInventory]: false,
        [Columns.WhNames]: false,
        [Columns.NextReplenishment]: false,
        [Columns.Coverage]: false,
        [Columns.ExpectedCoverage]: false,
        [Columns.Constraints]: true,
      },
      defaultColumnsOrder: [
        Columns.Size,
        Columns.Replenishment,
        Columns.Sold,
        Columns.SalesRate,
        Columns.Stock,
        Columns.OptimalStock,
        Columns.WhNames,
        Columns.NextReplenishment,
        Columns.WarehouseInventory,
        Columns.Coverage,
        Columns.ExpectedCoverage,
        Columns.Constraints,
      ],
      defaultPinnedColumnsOrder: [],
    });

    function reset() {
      resetColumnsSettings();
      width.value = defaultWidth;
    }

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const persistedState = await api
            .getUiStates({ key: StoreNames.ReplenishmentProductDetailsPage })
            .then(({ data }) => data.data[0]);
          reset();

          // Apply persisted state

          if (persistedState) {
            applyPersistedColumnsSettings({
              columnsVisibility: persistedState.value.columnsVisibility,
              columnsOrder: persistedState.value.columnsOrder,
              pinnedColumnsOrder: persistedState.value.pinnedColumnsOrder,
            });

            if (persistedState.value.width) {
              try {
                z.number().parse(persistedState.value.width);

                width.value = persistedState.value.width;
              } catch (error) {
                // do nothing
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentProductDetailsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          pinnedColumnsOrder: pinnedColumnsOrder.value,
          width: width.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumnsOrder: [...pinnedColumnsOrder.value],
      width: width.value,
    }));

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsVisibility,
      visibleColumns,
      columnsOrder,
      pinnedColumnsOrder,
      width,
      columnsSettingsTouched,
    };
  },
);
