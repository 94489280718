import { defineStore } from 'pinia';
import { z, type Schema } from 'zod';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { cloneDeep, isEqual } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { Sorting } from '../../../shared/types';
import { useAccountStore } from '../../../stores';
import { useTableColumnsSettingsStore } from '../../../composables';
import { useUiStatesApi } from '../../ui-states';
import type { InventoriesFilter } from '../interfaces';

enum Columns {
  LocationName = 'location_name',
  LocationExternalId = 'location_external_id',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  SkuDescription = 'sku_description',
  LocationDescription = 'location_description',
  ProductName = 'product_name',
  ProductExternalId = 'product_external_id',
  Size = 'size',
  SourceLocationExternalId = 'source_location_external_id',
  Styles = 'styles',
  Colors = 'colors',
  Categories = 'categories',
  DepartmentName = 'department_name',
  DepartmentExternalId = 'department_external_id',
  Brands = 'brands',
  Seasons = 'seasons',
  Markets = 'markets',
  Cost = 'cost',
  Price = 'price',
  RegionName = 'region_name',
  CityName = 'city_name',
  LocationType = 'location_type',
  Classifications = 'classifications',
  SourceLocations = 'source_locations',
  SiteQty = 'site_qty',
  TransitQty = 'transit_qty',
  AtWh = 'at_wh',
  LastStatusDate = 'last_status_date',
  ReservedQty = 'reserved_qty',
  SaleRate = 'sale_rate',
  OptimalStock = 'optimal_stock',
  MinTarget = 'min_target',
  MaxTarget = 'max_target',
  Coverage = 'coverage',
  CreatedAt = 'created_at',
  AvoidReplenishment = 'avoid_replenishment',
  AvoidReplenishmentForLocation = 'location_avoid_replenishment',
  AvoidReplenishmentForSku = 'sku_avoid_replenishment',
}

export const useInventoryInventoriesPageStore = defineStore(
  StoreNames.InventoryInventoriesPage,
  () => {
    const defaultSorting: Sorting = {
      sortBy: null,
      sortOrder: 'asc',
    };
    const defaultAppliedFilters: InventoriesFilter = {
      location_id: [],
      product_id: [],
      sku_id: [],
      location_type: [],
      styles: [],
      categories: [],
      seasons: [],
      brands: [],
      classifications: [],
      region: [],
      department: [],
      at_wh: [null, null],
      site_qty: [null, null],
      transit_qty: [null, null],
      avoid_replenishment: null,
      location_avoid_replenishment: null,
      sku_avoid_replenishment: null,
    };

    const sorting = ref<Sorting>({ ...defaultSorting });
    const appliedFilters = ref<InventoriesFilter>(cloneDeep(defaultAppliedFilters));

    const customColumns = ref<string[]>([]);

    const {
      columnsVisibility,
      columnsOrder,
      pinnedColumnsOrder,
      allColumns,
      visibleColumns,
      resetColumnsSettings,
      applyPersistedColumnsSettings,
      columnsSettingsTouched,
    } = useTableColumnsSettingsStore({
      defaultColumnsVisibility: {
        [Columns.LocationName]: true,
        [Columns.LocationExternalId]: false,
        [Columns.SkuName]: true,
        [Columns.SkuExternalId]: false,
        [Columns.SkuDescription]: false,
        [Columns.LocationDescription]: false,
        [Columns.ProductName]: true,
        [Columns.ProductExternalId]: false,
        [Columns.Size]: true,
        [Columns.SourceLocationExternalId]: false,
        [Columns.Styles]: false,
        [Columns.Colors]: false,
        [Columns.Categories]: false,
        [Columns.DepartmentName]: true,
        [Columns.DepartmentExternalId]: false,
        [Columns.Brands]: false,
        [Columns.Seasons]: false,
        [Columns.Markets]: false,
        [Columns.Cost]: false,
        [Columns.Price]: false,
        [Columns.RegionName]: false,
        [Columns.CityName]: false,
        [Columns.LocationType]: false,
        [Columns.Classifications]: false,
        [Columns.SourceLocations]: false,
        [Columns.SiteQty]: true,
        [Columns.TransitQty]: true,
        [Columns.AtWh]: true,
        [Columns.LastStatusDate]: true,
        [Columns.ReservedQty]: true,
        [Columns.SaleRate]: false,
        [Columns.OptimalStock]: false,
        [Columns.MinTarget]: false,
        [Columns.MaxTarget]: false,
        [Columns.Coverage]: false,
        [Columns.CreatedAt]: false,
        [Columns.AvoidReplenishment]: true,
        [Columns.AvoidReplenishmentForLocation]: true,
        [Columns.AvoidReplenishmentForSku]: true,
      },
      defaultColumnsOrder: [
        Columns.SkuDescription,
        Columns.LocationDescription,
        Columns.ProductName,
        Columns.ProductExternalId,
        Columns.Size,
        Columns.SourceLocationExternalId,
        Columns.Styles,
        Columns.Colors,
        Columns.Categories,
        Columns.DepartmentName,
        Columns.DepartmentExternalId,
        Columns.Brands,
        Columns.Seasons,
        Columns.Markets,
        Columns.Cost,
        Columns.Price,
        Columns.RegionName,
        Columns.CityName,
        Columns.LocationType,
        Columns.Classifications,
        Columns.SourceLocations,
        Columns.SiteQty,
        Columns.TransitQty,
        Columns.AtWh,
        Columns.LastStatusDate,
        Columns.ReservedQty,
        Columns.SaleRate,
        Columns.OptimalStock,
        Columns.MinTarget,
        Columns.MaxTarget,
        Columns.Coverage,
        Columns.CreatedAt,
        Columns.AvoidReplenishment,
        Columns.AvoidReplenishmentForLocation,
        Columns.AvoidReplenishmentForSku,
      ],
      defaultPinnedColumnsOrder: [
        Columns.LocationName,
        Columns.LocationExternalId,
        Columns.SkuName,
        Columns.SkuExternalId,
      ],
      customColumns,
    });

    function reset() {
      resetColumnsSettings();
      sorting.value = { ...defaultSorting };
      appliedFilters.value = cloneDeep(defaultAppliedFilters);
    }

    const api = useUiStatesApi();
    const accountStore = useAccountStore();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if (fetched.value && !force) {
        return fetchingPromise ?? Promise.resolve();
      }

      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const [persistedState] = await Promise.all([
            api
              .getUiStates({ key: StoreNames.InventoryInventoriesPage })
              .then(({ data }) => data.data[0]),
            accountStore.fetch(),
          ]);

          customColumns.value =
            accountStore.customDataSchema.Inventory?.map(({ key }) => `custom_data.${key}`) ?? [];

          reset();

          if (persistedState) {
            applyPersistedColumnsSettings({
              columnsVisibility: persistedState.value.columnsVisibility,
              columnsOrder: persistedState.value.columnsOrder,
              pinnedColumnsOrder: persistedState.value.pinnedColumnsOrder,
            });

            if (persistedState.value.sorting) {
              try {
                sorting.value = z
                  .object({
                    sortBy: z
                      .string()
                      .nullable()
                      .refine((value) => {
                        return value === null || allColumns.value.includes(value);
                      }),
                    sortOrder: z.enum(['asc', 'desc']),
                  })
                  .catch({ ...defaultSorting })
                  .parse(persistedState.value.sorting);
              } catch (error) {
                // do nothing
              }
            }

            if (persistedState.value.appliedFilters) {
              try {
                appliedFilters.value = cloneDeep(
                  z
                    .object(
                      Object.entries(defaultAppliedFilters).reduce<Record<string, Schema>>(
                        (acc, [key, value]) => {
                          // Range
                          if (
                            Array.isArray(value) &&
                            value.length === 2 &&
                            value.every((item) => item === null || typeof item === 'number')
                          ) {
                            acc[key] = z
                              .tuple([z.number().nullable(), z.number().nullable()])
                              .catch(value);
                            return acc;
                          }

                          // Multi select
                          if (Array.isArray(value)) {
                            acc[key] = z
                              .array(
                                z.string().or(z.object({ value: z.string(), label: z.string() })),
                              )
                              .catch(value as string[]);
                            return acc;
                          }

                          // Flag
                          if (value === null || typeof value === 'boolean') {
                            acc[key] = z.boolean().nullable().catch(value);
                            return acc;
                          }

                          return acc;
                        },
                        {},
                      ),
                    )
                    .parse(persistedState.value.appliedFilters) as InventoriesFilter,
                );
              } catch (error) {
                // do nothing
              }
            }
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    async function persist() {
      await api.saveUiState({
        key: StoreNames.InventoryInventoriesPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          pinnedColumnsOrder: pinnedColumnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      pinnedColumnsOrder: [...pinnedColumnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      reset,
      resetColumnsSettings,
      columnsSettingsTouched,
      columnsVisibility,
      visibleColumns,
      columnsOrder,
      pinnedColumnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
